import PropTypes from "prop-types";
import React from "react";

const ResourcesContent = ({
  className,
  reversed,
  pro,
  title,
  description,
  image,
}) => {
  return (
    <div
      className={`${className} flex ${
        reversed ? "flex-row-reverse lg:ml-48 md:ml-32" : "lg:mr-48 md:mr-32"
      } items-center justify-center m-8`}
    >
      <div
        className={`lg:w-1/2 ${
          reversed ? "lg:ml-32 md:ml-20" : "lg:mr-32 md:ml-20"
        }`}
      >
        <div className="flex items-center">
          <h2 className="font-bold mb-5">{title}</h2>
          {pro && <div className="pro-icon text-center ml-2 mb-4">FRA</div>}
        </div>
        <h3 className="text-gray">{description}</h3>
      </div>
      <img src={image} alt="" className="hidden md:block w-1/5" />
    </div>
  );
};

ResourcesContent.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pro: PropTypes.bool,
  reversed: PropTypes.bool,
  title: PropTypes.string,
};

export default ResourcesContent;
